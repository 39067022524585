.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main-container {
    background: url("https://cdn.snapplify.com/2.0.29/img/city_bg.png") no-repeat fixed 0 35%;
    background-size: cover;
    padding: 30px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.main-content-box {
    margin: 50px auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background: white;
    padding: 50px;
    border-radius: 12px;
}

ul {
    display: flex;
    flex-direction: row;
}

li {
    list-style-type: none;
}

a, a:hover {
    color: #b9cf46;
    cursor: pointer;
    font-weight: 400
}

.os-primary {
    display: block;
    margin: 0 auto;
    width: 160px;
    height: 160px;
    padding: 20px;
    border: 4px solid #b9cf46;
    border-radius: 25px;
}

.os-secondary {
    display: inline-block;
    background: white;
    margin: 5px;
    width: 125px;
    height: 125px;
    padding: 20px;
    border: 4px solid #D9D9D9;
    border-radius: 25px;
}

.os-secondary:hover {
    border: 4px solid #b9cf46
}

.img-sm {
    width: auto;
    border-color: black;
}

.section_heading {
    text-align: center;
    font-weight: 300
}

.section_text {
    margin: 28px;
    font-weight: 300;
}

.robot {
    height: 450px;
    width: auto;
    margin-left: 60px;
}

@media screen and (max-width: 1200px) {
    .robot {
        display: none;
    }

    .section_heading {
        margin-top: 25px;
    }
}

.gde-chooser {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 300;
    width: 100%;
    padding: 0 15px; /* Add padding for smaller screens */
}

.app-button {
    cursor: pointer;
    width: 45%;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
    display: flex;
    justify-content: space-between; /* Space the logos/buttons evenly */
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 0 15px; /* Maintain padding */
}

.logo-container img {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logo-container img:hover {
    transform: scale(1.05); /* Slightly enlarge the button on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

/* Media query for smaller screens (768px and below) */
@media screen and (max-width: 1024px) {
    .app-button {
        width: 100%;
        height: 100%;

    }

    .logo-container {
        flex-direction: column; /* Stack buttons vertically on small screens */
    }

    .logo-container img {
        margin-bottom: 20px; /* Add space between stacked buttons */
        max-width: none; /* Remove max-width restriction for small screens */
    }
}

/* Media query for very small screens (480px and below) */
@media screen and (max-width: 480px) {
    .logo-container img {
        padding: 8px; /* Adjust padding for very small screens */
        margin-bottom: 15px; /* Adjust spacing between buttons */
    }
}
